import React from "react";
import { Outlet } from 'react-router-dom';

export const Header = (props) => {
 
  return (
    <div>
      <header id="header">
        <div className="intro">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-sm-12 intro-text text-left">
                  <h1>
                    {props.data ? props.data.title : "Loading"} 
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a href="/about" className="btn btn-custom btn-lg page-scroll">
                    Nosotros
                  </a>{" "}
                </div>
                <div className="col-lg-4 col-sm-12 intro-text text-left">
                  <img className="img-responsive intro-image"  src="img/home/equipo.jpg" alt="Imagen descriptiva"/>
                </div>
              </div>
            </div> 
        </div>
      </header> 
      <section>
          <div>
          <Outlet  />
        </div>
      </section>
    </div>
   
    
  );
};
