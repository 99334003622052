import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  from_name: "",
  reply_to: "",
  message: "",
};
export const Contact = (props) => {
  const [{ from_name, reply_to, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { from_name, value } = e.target;
    setState((prevState) => ({ ...prevState, [from_name]: value }));
  };
  const clearState = () => setState({ ...initialState });
   
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(from_name, reply_to, message);
    
    emailjs
      .sendForm("service_og0t2r9", "template_uixczr8", e.target, "FtsGPm1t5M4BryP_Q")
      .then(
        (result) => { 
          alert("Mensaje enviado con éxito!");
          clearState();
        },
        (error) => {
          alert("Error al enviar el mensaje. Por favor, inténtalo de nuevo.");
        }
      );
  };
  return (
    <header id="contact">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="col-md-3"> 
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="section-title">
                <h2>Póngase en contacto con nosotros</h2>
              </div>
              <form from_name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="from_name"
                        name="from_name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="reply_to"
                        name="reply_to"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar
                </button>
              </form>
            </div>
          </div> 
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> 
          </div>
        </div>
      </div>
    </header> 
    
  );
};
