import React from "react";
import "./serviciosIntegral.css";   

const ServiciosIntegral = () => { 

  return (
    <div className="assembly">
        <article style={{ "--k": 0, color: "#26528C" , zIndex: 1}}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Evaluación</h3>
        </article>
        <article style={{ "--k": 0.16666666666666666, color: "#3C92A6" ,  zIndex: 1 }}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Planificación</h3> 
        </article>
        <article style={{ "--k": 0.3333333333333333, color: "#F2D399",  zIndex: 1 }}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Implementación</h3> 
        </article>
        <article style={{ "--k": 0.5, color: "#D9AB73", position: 'absolute', zIndex: 1 }}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Capacitación y desarrollo </h3> 
        </article>
        <article style={{ "--k": 0.6666666666666666, color: "#A67665",  zIndex: 1 }}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Evaluación de resultados</h3> 
        </article>
        <article style={{ "--k": 0.8333333333333334, color: "#abadb3" , zIndex: 1}}>
            <h3 style={{ position: 'relative', zIndex: 3 }}>Soporte Improvement</h3> 
        </article>
    </div>
  );
};
export default ServiciosIntegral;
