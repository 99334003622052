import React from "react";
import { Link } from "react-router-dom";
import "./navigation.css";

export const Navigation = () => {
  const handleLinkClick = () => {
    // Cierra el menú colapsable
    const navbarCollapse = document.getElementById('bs-example-navbar-collapse-1');
    if (navbarCollapse.classList.contains('in')) {
      navbarCollapse.classList.remove('in');
    }
    window.scrollTo(0, 0);
  };
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a href="/#home" className="">
            <img
              src="/img/logo/logo1.png"
              alt="Logo"
              className="navbar-logo"
            />
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-center">
            <li>
              <Link to="/" className="page-scroll" onClick={handleLinkClick}>
                Inicio
              </Link>
            </li>
            <li>
              <Link to="/about" className="page-scroll" onClick={handleLinkClick}>
                Nosotros
              </Link>
            </li>
            <li>
              <Link to="/services" className="page-scroll" onClick={handleLinkClick}>
                Servicios
              </Link>
            </li>
            <li>
              <Link to="/contact" className="page-scroll" onClick={handleLinkClick}>
                Contáctanos
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
