import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer"> 
      <div className="container">
        <div className="footer-content">
          <div className="footer-section about">
            <h3>Información</h3>
            <p>
              Improvemente consulting E.I.R.L © 2024 | Derechos Reservados | Terminos de Uso | Protección de datos personales.
            </p>
          </div>
          <div className="footer-section links">
            <h3>Atajos</h3>
            <ul>
              <li><a href="/">Inicio</a></li>
              <li><a href="/about">Nosotros</a></li>
              <li><a href="/services">Services</a></li> 
              <li><a href="/contact">Contactanos</a></li>
            </ul>
          </div>
          <div className="footer-section contact">
            <h3>Contáctanos</h3>
            <p><i className="fa fa-map-marker"></i> Av. Angelica Gamarra 619, Lima, Peru</p>
            <p><i className="fa fa-phone"></i> +51 994 955 279</p>
            <p><i className="fa fa-envelope"></i> info@improvementconsulting-global.com</p>
           <div className="socials">
            <a href="https://www.facebook.com/tu-pagina"><i className="fa fa-facebook"></i></a>
            <a href="https://www.twitter.com/tu-pagina"><i className="fa fa-twitter"></i></a>
            <a href="https://www.linkedin.com/in/tu-pagina"><i className="fa fa-linkedin"></i></a>
            <a href="https://www.instagram.com/tu-pagina"><i className="fa fa-instagram"></i></a>
          </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Improvement Consulting. All rights reserved.</p>
          <p>Designed by IMPROVEMENT CONSULTING</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
