import React from 'react';
import ReactDOM from 'react-dom/client'; // Actualiza la importación
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root')); // Usa createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si deseas que tu aplicación funcione sin conexión y cargue más rápido,
// puedes cambiar unregister() a register() a continuación. Ten en cuenta
// que esto tiene algunas desventajas. Aprende más sobre los service workers:
// https://bit.ly/CRA-PWA
serviceWorker.unregister();
