import React from "react";
import "./services.css"; 
import ServiciosIntegral from '../Home/serviciosIntegral';
import ServiciosIntegralDet from './serviciosIntegralDet';
export const Services = (props) => {
  return (
    <div>
       <header id="services">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-12 intro-text text-left">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header> 
      <section id="services2">
        <div className="container">
          <div className="section-title text-center">
            <h2>Nuestros Servicios</h2>
          </div>
          <div className="row">
           {props.data
              ? props.data.Servicios.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="services-container" >
                    <div className=" service-item col-md-4" style={{  background: d.color}}>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </section>
      <section id="home3" className="text-center">  
        <div className="section-title">
          <h2>Metodología Integral Improvement - EPICES</h2>
        </div>
        <div className="row">
          <ServiciosIntegral />
        </div> 
      </section>
       <section id="services3" className="text-center">  
        <div className="container">
          <div className="section-title">
            <h2>Nuestro servicio integral</h2>
          </div>
          <div>
            <ServiciosIntegralDet />
          </div>  
        </div> 
      </section>
      <section id="lema"> 
      <div className="footer-top">
        <div className="footer-top-box"> 
          <h2 className="footer-slogan">"Impulsando tu crecimiento"</h2>
          <a href="/contact" className="footer-contact-button">Contáctanos</a>
        </div>
      </div>
     </section>
    </div>
  );
};
