import React from "react";
import "./about.css";
export const About = (props) => {
  return (
    <div>
      <header id="about">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-12 intro-text text-left">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="pillars" className="text-center">
        <div className="container">
          <div className="section-title">
            <h3>lo que nos motiva</h3>
            <h2>Nuestros Pilares</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="pillar-box">
                <h3>Misión</h3>
                <p>{props.data ? props.data.mission : "Loading"}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pillar-box">
                <h3>Visión</h3>
                <p>{props.data ? props.data.vision : "Loading"}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pillar-box">
                <h3>Valores</h3>
                <p>{props.data ? props.data.values : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about2">
        <div className="container">
          <h2>EXPERIENCIA MULTIDICIPLINARIA</h2>
          <h3 style={{ fontWeight: 'bold' }}>NUESTRO EQUIPO</h3>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              {" "}
              <img src="img/team/Equipo1.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="home-text">
                <h3></h3>
                <h3>¿QUIENES SOMOS?</h3>
                <h3>somos el mejor equipo  comprometido con tu éxito</h3>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="stats-container">
                <div className="stat">
                  <h3 className="large-number">15Y</h3>
                  <p>Experiencia</p>
                </div>
                <div className="stat">
                  <h3 className="large-number">120+</h3>
                  <p>El mejor equipo</p>
                </div>
                <div className="stat">
                  <h3 className="large-number">50+</h3>
                  <p>Total client</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     <section id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h3>Nuestro equipo</h3>
          <h2>Experiencia y confianza</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.Team.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                  <div className="thumbnail">
                    {/* Placeholder visible hasta que la imagen termine de cargar */}
                    <div className="image-placeholder">
                      <img
                        src={d.img}
                        alt={d.name}
                        className="team-img"
                        loading="lazy"
                        onLoad={(e) => e.target.style.opacity = 1} // Mostrar imagen cuando cargue
                        style={{ opacity: 0, transition: 'opacity 0.5s ease-in-out' }} // Animación para suavizar la transición
                      />
                    </div>
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </section>
     <section id="lema"> 
      <div className="footer-top">
        <div className="footer-top-box-red"> 
          <span className="footer-slogan">“llevando tu éxito al siguiente nivel”</span>
          <a href="/contact" className="footer-contact-button-red">Contáctanos</a>
        </div>
      </div>
     </section>
    </div>
  );
};
