import React from "react";    
import ImageCarousel from './carousel';
import ServiciosIntegral from './serviciosIntegral';
import './home.css';

export const Home = (props) => {  
  return ( 
    <div>
<section> 
      <div id="home"> 
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              {" "}
              <img src="img/about1.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="home-text">
                <h3></h3>
                <h3>¿QUIENES SOMOS?</h3>
                <h2>Somos un equipo multidisiplinario comprometido</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <div className="list-style">
                  <div className="col-xs-12 col-md-6">
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="stats-container">
                <div className="stat">
                  <h3 className="large-number">15Y</h3>
                  <p>Experiencia</p>
                </div>
                <div className="stat">
                  <h3 className="large-number">120+</h3>
                  <p>El mejor equipo</p>
                </div>
                <div className="stat">
                  <h3 className="large-number">50+</h3>
                  <p>Total client</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div id="home2" className="text-center">
            <div className="container">
              <div className="section-title">
                <h2>Principios Fundamentales</h2>
              </div>
              <div className="row">
                {props.data
                  ? props.data.Beginning.map((d, i) => (
                      <div key={`${d.name}-${i}`} className="col-md-4">
                        {" "}
                        <i className={d.icon}></i>
                        <div className="service-desc">
                          <h3>{d.name}</h3>
                          <p>{d.text}</p>
                        </div>
                      </div>
                    ))
                  : "loading"}
              </div> 
            </div> 
          </div> 
        </div>
      </div> 
      <div id="carousel">
        <div className="container">
          <div className="row">
              <ImageCarousel />
          </div>
        </div> 
      </div>
      <div id="home3" className="text-center">  
        <div className="section-title">
          <h2>Nuestro servicio integral</h2>
        </div>
        <div className="row">
          <ServiciosIntegral />
        </div> 
      </div>
      <div id="home4" className="text-left">
        <div className="container">  
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="home-text">
                <h3></h3>
                <h3>Su exito es nuestro exito</h3>
                <h2>Porqué elegir nuestro servicio</h2>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p> 
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why3.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data
                        ? props.data.Why4.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </section>
     <section id="lema"> 
      <div className="footer-top">
        <div className="footer-top-box"> 
          <span className="footer-slogan">Transformando ideas en realidades, potenciando tu éxito</span>
          <a href="/contact" className="footer-contact-button">Contáctanos</a>
        </div>
      </div>
     </section>
    </div>
  );
};

export default Home;