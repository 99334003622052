import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Navigation } from "./components/Navigation/navigation";
import { Header } from "./components/Home/header";
import { Home } from "./components/Home/home";
import { Services } from "./components/Services/services";
import Footer from "./components/Footer/footer";
//import { Features } from "./components/features";
import { About } from "./components/About/about"; 
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll"; 
import "./App.css";

const InitialLogo = () => {
  return (
    <div className="initial-logo-container">
      <img src="img/fondos/fondo1.png" alt="Improvement Consulting Logo" className="initial-logo" />
      <div className="overlay-logo-container">
        <img src="img/logo/logo1.png" alt="Small Logo" className="overlay-logo" />
      </div>
    </div>
  );
};

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showLogo, setShowLogo] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLandingPageData(JsonData);

    // Verificar si el logo ya fue mostrado antes
    const hasSeenLogo = localStorage.getItem('hasSeenLogo3');
    if (location.pathname === '/' && hasSeenLogo === 'false') {
      setShowLogo(true);

      // Ocultar el logo después de 3 segundos (3000 ms)
      const timer = setTimeout(() => {
        setShowLogo(false);
        localStorage.setItem('hasSeenLogo3', 'true'); // Marcar como visto
      }, 3000);

      return () => clearTimeout(timer);
    } else if (location.pathname === '/') {
      localStorage.setItem('hasSeenLogo3', 'false');
    }
  }, [location.pathname]);

  return (
    <>
      {showLogo && <InitialLogo />}
      {!showLogo && (
        <>
          <Navigation />
          <Routes>
            <Route path="/" element={<Header data={landingPageData.Header} />}>
              <Route index element={<Home data={landingPageData.Home} />} />
            </Route>
            <Route path="/about" element={<About data={landingPageData.About} />} />
            <Route path="/services" element={<Services data={landingPageData.Services} />} />
            <Route path="/gallery" element={<Gallery data={landingPageData.Gallery} />} />
            <Route path="/testimonials" element={<Testimonials data={landingPageData.Testimonials} />} />
            <Route path="/team" element={<Team data={landingPageData.Team} />} />
            <Route path="/contact" element={<Contact data={landingPageData.Contact} />} />
          </Routes>
          <Footer /> {}
        </>
      )}
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;