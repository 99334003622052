import React from "react";
import "./serviciosIntegralDet.css";   
 
const serviciosIntegralDet = () => (
  <div> 
    <div className="row">
      <div className="col-xs-12 col-md-6"> 
        <img src={'img/service/serviceIntegral01.png'} className="img-responsive feature-image" alt="" />{" "}
      </div>
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">Evaluación</h3> 
          <p className="feature-description">Comprender las necesidades y desafíos del cliente. Reunión inicial con el cliente para discutir objetivos, problemas y expectativas.</p>
          <div className="list-style">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Reunión de Diagnóstico
                </li>
                <li>Análisis Situacional
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>  
    </div>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">planificación y estrategia</h3> 
          <p className="feature-description">Establecer metas claras y alcanzables alineadas con la visión del cliente. Crear un plan detallado con pasos específicos para alcanzar los objetivos establecidos.</p>
          <div className="list-style">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Definición de Objetivos
                </li> 
                <li>Desarrollo del Plan de Acción
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-6"> 
        <img src={'img/service/serviceIntegral02.png'} className="img-responsive feature-image" alt="" />{" "}
      </div> 
    </div> 
    <div className="row">
      <div className="col-xs-12 col-md-6">  
           <img src={'img/service/serviceIntegral03.png'} className="img-responsive feature-image" alt="" />{" "}
      </div>
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">Implementación</h3> 
          <p className="feature-description">Poner en marcha las acciones planificadas. Asegurar que el proyecto se mantenga en curso y se ajusten las acciones según sea necesario.</p>
          <div className="list-style">
             <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Ejecución del Plan
                </li>
                <li>Monitoreo y Control
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <div className="row"> 
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">Capacitación y desarrollo</h3> 
          <p className="feature-description">Asegurar que el equipo del cliente esté capacitado para mantener y optimizar los nuevos procesos.  Proveer apoyo continuo y desarrollo profesional para líderes y empleados clave.</p>
          <div className="list-style">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Formación del Personal
                </li>
                <li>Mentoring
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-6"> 
        <img src={'img/service/serviceIntegral04.png'} className="img-responsive feature-image" alt="" />{" "}
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-md-6">  
        <img src='img/service/serviceIntegral05.png' className="img-responsive feature-image" alt="" />{" "}
      </div>
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">evaluación de resultados</h3> 
          <p className="feature-description">Evaluar el éxito del proyecto y el cumplimiento de los objetivos establecidos.  Documentar los resultados y aprendizajes del proyecto.</p>
          <div className="list-style">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Medición del Impacto
                </li>
                <li>Informe Final
                </li>
              </ul> 
            </div> 
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <div className="feature-card">
          <h3 className="feature-title">Soporte Improvement</h3> 
          <p className="feature-description">Asegurar la sostenibilidad de las mejoras implementadas.  Adaptarse a cambios futuros y mejorar continuamente.</p>
          <div className="list-style">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <ul>
                <li>Asesoría Post-Implementación
                </li>
                <li>Actualización y Mejora Continua
                </li>
              </ul>
            </div> 
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-6">  
        <img src='img/service/serviceIntegral06.png' className="img-responsive feature-image" alt="" />{" "}
      </div>
    </div>
  </div>
);
export default serviciosIntegralDet;
